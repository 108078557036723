import React from "react";
import PropTypes from "prop-types";

import WithThemeLoading from "../hoc/withThemeLoading";
import Theme1Page from "../themes/theme1/theme1Page";
import Theme2Page from "../themes/theme2/theme2Page";
import Theme3Page from "../themes/theme3/theme3Page";
import Theme4Page from "../themes/theme4/theme4Page";
import Theme5Page from "../themes/theme5/theme5Page";

const SitePage = (props) => {
  const { template, locale } = props.pageContext;

  /** Begin: Theme Switcher */
  const templateId =
    process.env.GATSBY_TEST_THEME === "0"
      ? template.id
      : process.env.GATSBY_TEST_THEME;

  const themes = [Theme1Page, Theme2Page, Theme3Page, Theme4Page, Theme5Page];
  const ThemeLoading =
    parseInt(templateId) > 0
      ? WithThemeLoading(themes[parseInt(templateId) - 1])
      : WithThemeLoading(Theme1Page);
  /** End: Theme Switcher */
  return <ThemeLoading locale={locale} {...props} />;
};

SitePage.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default SitePage;
