import React, { Component } from "react";
import PropTypes from "prop-types";

import Layout from "./components/layout";
import SEO from "./components/seo";
import { getTranslatedValue } from "../../helpers/translationHelper";
import * as theme4Styles from "../theme4/scss/theme4.module.scss";

class Theme4Page extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { pageContext, result } = this.props;
    const { websitePage, locale } = pageContext;
    const { defaultLocaleKey: defaultLocale } = result;

    const content = getTranslatedValue(
      websitePage.translations,
      "content",
      locale,
      defaultLocale
    );

    const metaTitle = getTranslatedValue(
      websitePage.translations,
      "meta_title",
      locale,
      defaultLocale
    );

    return (
      <Layout>
        <SEO metaTitle={metaTitle} />
        <div className={`${theme4Styles.mainContent}`}>
          <div className={theme4Styles.wrapper}>
            <h1 className={`${theme4Styles.pageTitle}`}>{websitePage.name}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              className={`${theme4Styles.contentArea}`}
            ></div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Theme4Page;

Theme4Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
};
